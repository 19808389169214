//     
import React, { PureComponent } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

                                          

export default class ProgressIndicator extends PureComponent        {
  render() {
    const inProgress = this.props.inProgress || 0;

    return inProgress > 0 ? <LinearProgress mode="indeterminate" /> : null;
  }
}

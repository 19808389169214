//     
import React, { PureComponent } from 'react';
import HamburgerIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';

              
              
                                               
  

              
                         
  

export default class MenuButton extends PureComponent               {
  state = {
    anchorEl: null,
  };

  closeMenu = () => {
    this.setState({ anchorEl: null });
  };

  openMenu = (e                             ) => {
    this.setState({ anchorEl: e.currentTarget });
  };

  render() {
    const { label, children } = this.props;

    return (
      <>
        <Button aria-label={'Open Menu'} variant="outlined" onClick={this.openMenu}>
          {label}
          <HamburgerIcon
            color={'inherit'}
            style={{
              height: 16,
              width: 16,
              marginLeft: 5,
            }}
          />
        </Button>
        <Menu
          disableScrollLock
          open={!!this.state.anchorEl}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          onClose={this.closeMenu}
        >
          {children({
            onClick: () => {
              this.setState({ anchorEl: null });
            },
          })}
        </Menu>
      </>
    );
  }
}

//     
import React, { memo } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
                                  

               
                 
              
               
               
              
              
               
                                                                  
               
                   
                          
   

const variantStyle = {
  info: { bgcolor: palette.blue50, color: palette.blue10 },
  warning: { bgcolor: palette.yellow50, color: palette.yellow10 },
  error: { bgcolor: palette.red50, color: palette.red10 },
  success: { bgcolor: palette.green50, color: palette.green10 },
  disabled: { bgcolor: palette.gray50, color: palette.gray10 },
  default: { bgcolor: palette.white, color: palette.gray10 },
};

const useStyles = makeStyles({
  // use entire given `style` but override
  // display: flex & justifyItems: center
  // to inline icon & text.
  box: props => ({
    ...(props.style && props.style),
    display: 'flex',
    alignItems: 'center',
  }),
});

const MessageComponent = function Message({
  style,
  m,
  mb,
  mt,
  p,
  c,
  bg,
  variant,
  icon,
  message,
  messageHeader,
}       ) {
  const classes = useStyles({ style });
  const padding = typeof p !== 'undefined' ? p : 1;
  const margin = typeof m !== 'undefined' ? m : 2;
  const marginBottom = typeof mb !== 'undefined' ? mb : typeof m !== 'undefined' ? m : 2;
  const marginTop = typeof mt !== 'undefined' ? mt : typeof m !== 'undefined' ? m : 2;
  const color = typeof c !== 'undefined' ? c : 'inherit';
  const bgColor = typeof bg !== 'undefined' ? bg : variantStyle[variant].bgcolor;

  return (
    <Box
      bgcolor={bgColor}
      color={variantStyle[variant].color}
      p={padding}
      m={margin}
      mb={marginBottom}
      mt={marginTop}
      borderRadius={2}
      whiteSpace="pre-line" // Used to allow messages to be multiline strings.
      className={classes.box}
    >
      {icon && (
        <Box component="div" width="auto" m={0} pr={1}>
          {icon}
        </Box>
      )}
      <Box component="div" width="auto" m={0}>
        {messageHeader && (
          <Typography variant={'subtitle2'} style={{ color: color, marginBottom: '10px' }}>
            {messageHeader}
          </Typography>
        )}
        <Typography variant="body1">{message}</Typography>
      </Box>
    </Box>
  );
};

export const Message = memo       (MessageComponent);

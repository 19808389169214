//     
                        

export const palette = {
  brand10: '#0C0F4C',
  brand20: '#0A215C',
  brand30: '#123770',
  brand35: '#356DAF',
  brand40: '#5A96CD',
  brand45: '#94BFDF',
  brand50: '#CCE3F5',
  // Symlink of brand30
  brand: '#123770',

  // platform specific 'official' colors
  androidGreen: '#A4C639',
  appleSkyBlue: '#78C5EF',

  blue10: '#003B80',
  blue20: '#0053B3',
  blue30: '#4099FF',
  blue40: '#A2CDFF',
  blue50: '#C5DFFD',
  // Symlink of blue30
  blue: '#4099FF',

  red10: '#981b1e',
  red20: '#cd2026',
  red30: '#e31c3d',
  red40: '#e59393',
  red50: '#f9dede',
  // Symlink of red30
  red: '#e31c3d',

  yellow10: '#e39e02',
  yellow20: '#fdb81e',
  yellow30: '#f9c642',
  yellow40: '#fad980',
  yellow50: '#fff1d2',
  yellowHighlight: '#FAFA33',
  // Symlink of yellow30
  yellow: '#f9c642',

  green10: '#215f2e',
  green20: '#2e8540',
  green30: '#4aa564',
  green40: '#94bfa2',
  green50: '#e7f4e4',
  // Symlink of green30
  green: '#4aa564',

  orange10: '#C95C08',
  orange20: '#DC6832',
  orange30: '#F87530',
  orange40: '#FAAA7F',
  orange50: '#FFD2B8',
  // Symlink of orange30
  orange: '#FF7043',

  gray10: '#1F2933',
  gray20: '#3E4C59',
  gray30: '#616E7C',
  gray35: '#9AA5B1',
  gray40: '#CBD2D9',
  gray45: '#E4E7EB',
  gray50: '#F5F7FA',
  // Symlink of gray30
  gray: '#616E7C',

  black: '#000',
  white: '#fff',

  // @lab colors
  // These colors are experimental and can be changed/deprecated anytime!
  teal: '#00B8D9',

  brown: '#8D6E63',

  // @Deprecated
  // Please do not use any of the colors below after January 2019 or you will be fired!
  // primary: '#123770',
  // warning: '#f9c642',
  secondary: '#4099FF',
  accent: '#f2f2f2',
  attention: '#FF2D55',
  navColor: '#878DA3',
  darkestBg: '#000',
  brightBg: '#fff',
  bgColor: '#fff',
  inacitveColor: '#BBC0D4',
  darkBg: '#1C1429',
  error: '#FF4867',
  corrected: '#16ac8b',
  tableDark: '#f9f9fa',
  tableLight: '#fdfdfd',
  faded: '#777',
  bad: '#E57373',
  info: '#29B6F6',
  good: '#4CAF50',
};

export const fontFamilies = {
  primary: `'Rubik', 'Roboto', -apple-system, BlinkMacSystemFont, 'sans-serif'`,
  mono: `'Fira Mono', Menlo, monospace`,
};

export const fontSizes = {
  tiny: 9,
  small: 12,
  medium: 13,
  large: 16,
  huge: 32,
};

export const spacing = {
  menuWidth: 231,
  headerHeight: 50,
};

export const dateFormats = {
  date: 'MM/DD/YYYY',
  date12h: 'MM/DD/YYYY, h:mm aa',
  date24h: 'MM/DD/YYYY, HH:mm',
  yearFirstDate: 'MM/DD/YYYY',
};

// @SJ TODO: This mapping is an artifact of the "type" prop chain that was used for SecurityTaskIndicator, SecurityTaskIcon, IconCountIndicator (and a myriad other related components) throughout Apollo. Patching for now, but in the future need to refactor these components to not use this convention, in favor of the below mappings which are more specific.
export const oldPaletteToNewPaletteMap = {
  good: palette.green,
  bad: palette.red,
  warning: palette.yellow,
  info: palette.blue,
  neutral: palette.gray,
};

                                                                                 

export const materialTheme = (opts                                       )      => ({
  palette: {
    default: {
      main: palette.gray30,
      dark: palette.gray40,
    },
    primary: {
      light: palette.brand40,
      main: palette.brand,
      dark: palette.brand20,
    },
    secondary: {
      light: palette.gray40,
      main: 'rgb(228, 231, 235)',
      dark: 'rgb(245, 245, 245)',
    },
    error: {
      light: palette.red40,
      main: palette.red,
      dark: palette.red20,
    },

    grey: {
      '50': palette.gray50,
      '100': palette.gray50,

      '200': palette.gray45,
      '300': palette.gray45,

      '400': palette.gray40,
      '500': palette.gray40,

      '600': palette.gray35,
      '700': palette.gray30,
      '800': palette.gray20,
      '900': palette.gray10,
    },
    text: {
      primary: palette.gray20,
      secondary: palette.gray30,
      disabled: palette.gray35,
      hint: palette.gray40,
    },
  },
  typography: {
    fontFamily: fontFamilies.primary,
    fontSize: 14,
    useNextVariants: true,

    // caption
    caption: {
      color: palette.gray30,
    },

    // Header5
    h5: {
      color: palette.gray20,
    },

    // Subheader
    h6: {
      fontSize: '0.8rem',
      color: palette.gray20,
      textTransform: 'uppercase',
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },

    body1: {
      fontSize: '16px',
      color: palette.gray20,
    },
    // body2
    body2: {
      fontSize: '14px',
      color: palette.gray30,
    },
  },
  props: {
    MuiCheckbox: {
      color: 'primary',
    },
    MuiLink: {
      component: (opts && opts.linkComponent) || null,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'inherit',
        color: 'inherit'
      },
      styleOverrides: {
        root: {
          backgroundColor: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },

        // variant = contained & color = default
        containedPrimary: {
          backgroundColor: palette.brand30,
          color: palette.white,
          '&:hover': {
            backgroundColor: palette.brand10,
          },
        },

        // variant = contained & color = Secondary
        containedInherit: {
          backgroundColor: 'none',
          color: palette.gray20,
          '&:hover': {
            backgroundColor: palette.gray40,
          },
        },
        containedSecondary: {
          backgroundColor: 'none',
          '&:hover': {
            backgroundColor: palette.gray40,
          },
        },

        // variant = text & color = Primary
        textPrimary: {
          backgroundColor: 'none',
          color: palette.brand30,
          '&:hover': {
            backgroundColor: palette.gray45,
          },
        },
        // variant = text & color = Secondary
        textSecondary: {
          backgroundColor: 'none',
          color: palette.brand30,
          '&:hover': {
            backgroundColor: palette.gray45,
          },
        },
        
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        size: 'small',
        variant: 'standard',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        size: 'small',
      },
    },
  },
});

export default () => ({
  fontFamily: fontFamilies.primary,
  dataTheorem: {
    fontFamily: fontFamilies.primary,
    fontFamilyBold: fontFamilies.primary,
    fontFamilyAlt: fontFamilies.primary,
    fontSize: fontSizes,
    palette: palette,
    spacing: spacing,
  },
  palette: {
    accent1Color: '#4099FF',

    // Override Material-ui primary color to our brand primary color
    // more options to replace: https://github.com/mui-org/material-ui/blob/v0.x/src/styles/getMuiTheme.js#L21
    primary1Color: palette.brand,
  },
  dateFormats,
});
